<template>
<div>
  <div class = "menu_icons_mobile">
      <img v-on:click="show_mobile_menu" class = "hamburger_icon" src = "../../assets/img/hamburger.png" v-if="!mobile_menu_visible">
      <img v-on:click="hide_mobile_menu" class = "close_icon" src = "../../assets/img/close.png" v-if="mobile_menu_visible">
  </div>

  <div id="nav">
    <router-link to="/backoffice/home">דף בית</router-link> |
    <router-link to="/backoffice/distribution_points">נק' חלוקה</router-link> |
    <router-link to="/backoffice/endusers">לקוחות קצה</router-link> |
    <router-link to="/backoffice/courier_companies">חברות הפצה</router-link> |
    <router-link to="/backoffice/backoffice_casuals_couriers"> שליח מזדמן</router-link> |
    <router-link to="/backoffice/backoffice_users">ניהול אדמין </router-link> |
    <router-link to="/backoffice/statistics"> סטטיסטיקות</router-link> |
    <router-link to="/backoffice/logout">יציאה</router-link>
  </div>

  <div id="mobile_nav" v-if="mobile_menu_visible">
    <router-link to="/backoffice/home">דף בית</router-link>
    <router-link to="/backoffice/distribution_points">נק' חלוקה</router-link>
    <router-link to="/backoffice/endusers">לקוחות קצה</router-link> |
    <router-link to="/backoffice/courier_companies">חברות הפצה</router-link> |
    <router-link to="/backoffice/backoffice_casuals_couriers"> שליח מזדמן</router-link> |
    <router-link to="/backoffice/backoffice_users">ניהול אדמין </router-link>|
    <router-link to="/backoffice/statistics"> סטטיסטיקות</router-link> |
    <router-link to="/backoffice/logout">יציאה</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'Menu',
  props : ['mobile_menu_visible', 'show_mobile_menu', 'hide_mobile_menu']
}
</script>

<style scoped>
    @import "../../assets/style.css"
</style>